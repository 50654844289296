<template>
  <div id="app">
    <div class="relation_all">
      <div class="relation_all_in">
        <div class="relation_t">请选择关联身份</div>
        <div class="relation_btn">
          <router-link to="bind_student">
            家长
          </router-link>
        </div>
        <div class="relation_btn orange">
          <router-link to="bind_employ">
            教职工
          </router-link>
        </div>

        <div @click="clearData" style="text-align: center; margin-top: 16px; color: #999999">清理缓存并重新登录</div>
      </div>
    </div>
  </div>
</template>


<style scoped>

</style>

<script>
import Vue from "vue";
import {ACCESS_TOKEN, ACCESS_Customer, ACCESS_CustomerUser} from "../store/mutation-types";

export default {
  data() {
    return {
      back: false,
      backPath: null,
    };
  },
  mounted() {
    this.back = this.$route.query.back == 'true';
    this.backPath = this.$route.query.backPath;
  },
  methods: {
    backIndex() {
      let backPath = null;
      if (this.backPath) {
        switch (this.backPath) {
          case 'applyRechargeList':
            backPath = '/recharge/applyRechargeList';
            break
        }
      }

      if (!backPath) {
        backPath = '/index';
      }

      this.$router.push(backPath);

    },
    clearData() {
      Vue.ls.set(ACCESS_TOKEN, null);
      Vue.ls.set(ACCESS_Customer, null);
      Vue.ls.set(ACCESS_CustomerUser, null);

      this.$router.push({path: '/login'});
    }
  }
};
</script>
